/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import JitsuField from './JitsuField'
import JitsuLabel from './JitsuLabel'
import JitsuMutliselectOpenOptions from './JitsuMultiselectOpenOptions'
import JitsuMultiselectToggle from './JitsuMultiselectToggle'
import JitsuSimpleMultiselectOptions from './JitsuSimpleMultiselectOptions'
import JitsuMultiselectSelection from './JitsuSimpleMultiselectSelection'
import { createMap } from '../functions'
import ClickOutside from '../components/ClickOutside'
import { useMultiSelectOptionWrapper } from '../hooks/useMultiSelectOptionWrapper'

function JitsuSimpleMultiselect({
  value,
  valueKey,
  lockedOptions,
  onChange,
  limit,
  name,
  isSingleSelect,
  textKey,
  options,
  label,
  open,
  error,
  optional,
  required,
  tooltip,
  id,
  hasOptionsInModal,
  hint,
}) {
  const [expanded, setExpanded] = useState(false)
  const selectionsMap = createMap(value, valueKey)
  const [lockedOptionsMap] = useState(createMap(lockedOptions, valueKey))
  const onCloseModal = () => {
    setExpanded(false)
  }
  const toggleExpansion = () => {
    setExpanded(!expanded)
  }
  const hideExpansion = () => {
    setExpanded(false)
  }
  // eslint-disable-next-line no-unused-vars
  const ClickOutsideTag = hasOptionsInModal ? ClickOutside : Fragment
  const clickOutsideProps = hasOptionsInModal
    ? {
        onClickOutside: hideExpansion,
        className: 'w-full',
      }
    : Fragment

  const { Tag, modalProps } = useMultiSelectOptionWrapper({
    onCloseModal,
    expanded,
    hasOptionsInModal,
    label,
  })

  const onSelect = (selected) => {
    if (lockedOptionsMap[selected[valueKey]]) {
      return
    }
    if (isSingleSelect) {
      if (value[0] && selected[valueKey] === value[0][valueKey]) {
        onChange([], name)
        return
      }
      onChange([selected], name)
      return
    }
    const newSelections = selectionsMap[selected[valueKey]]
      ? value.filter((selection) => selection[valueKey] !== selected[valueKey])
      : value.concat(selected)
    if (newSelections.length > limit) return
    onChange(newSelections, name)
  }
  return (
    <JitsuField error={error}>
      <JitsuLabel
        optional={optional}
        required={required}
        tooltip={tooltip}
        hint={hint}
      >
        {label}
      </JitsuLabel>
      <div className="flex w-full items-center relative">
        <div className="flex flex-col w-full items-center relative">
          {/* <ClickOutsideTag {...clickOutsideProps}> */}
          <div className="w-full">
            {!open && (
              <>
                <div
                  className="mb-2 flex border border-gray-200 bg-white rounded"
                  style={{ minWidth: '300px' }}
                >
                  <JitsuMultiselectSelection
                    selection={value}
                    textKey={textKey}
                    valueKey={valueKey}
                    remove={onSelect}
                    clickSelectionArea={toggleExpansion}
                    id={id}
                    lockedOptionsMap={lockedOptionsMap}
                  />
                  <JitsuMultiselectToggle
                    expanded={expanded}
                    onClick={toggleExpansion}
                    id={id}
                  />

                  {expanded && (
                    <Tag {...modalProps}>
                      <JitsuSimpleMultiselectOptions
                        options={options}
                        textKey={textKey}
                        valueKey={valueKey}
                        onClickOption={onSelect}
                        expanded={expanded}
                        lockedMap={lockedOptionsMap}
                        selectionsMap={selectionsMap}
                        hasOptionsInModal={hasOptionsInModal}
                        id={id}
                      />
                    </Tag>
                  )}
                </div>
              </>
            )}
            {open && (
              <JitsuMutliselectOpenOptions
                options={options}
                textKey={textKey}
                valueKey={valueKey}
                onClickOption={onSelect}
                expanded={expanded}
                lockedMap={lockedOptionsMap}
                selectionsMap={selectionsMap}
                id={id}
              />
            )}
          </div>
          {/* </ClickOutsideTag> */}
        </div>
      </div>
    </JitsuField>
  )
}

JitsuSimpleMultiselect.defaultProps = {
  value: [],
  error: '',
  lockedOptions: [],
  isSingleSelect: false,
  onChange: () => {},
  options: [],
  id: 'custom-select',
  expanded: false,
  open: false,
  hasOptionsInModal: true,
}

JitsuSimpleMultiselect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  lockedOptions: PropTypes.array.isRequired,
  valueKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array.isRequired,
  limit: PropTypes.number,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isSingleSelect: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
  hasOptionsInModal: PropTypes.bool,
  hint: PropTypes.string,
}

export default JitsuSimpleMultiselect
