import PropTypes from 'prop-types'
import React from 'react'
import { XCircleIconSolid } from '../assets/icons'
import useAppTheme from '../hooks/useAppTheme'

function JitsuMultiselectSelection({
  selection,
  textKey,
  valueKey,
  remove,
  clickSelectionArea,
  id,
  lockedOptionsMap,
}) {
  const {
    textPrimary600,
    hoverTextPriamry700,
    textPriamry700,
    bgPrimary100,
    borderPrimary300,
  } = useAppTheme()
  return (
    <div className="flex flex-auto flex-wrap pl-2" id={`${id}_selection`}>
      {selection.length === 0 ? (
        <div
          role="button"
          className="pt-2 w-full cursor-pointer text-xs font-light text-gray-700"
          onClick={clickSelectionArea}
          id={`${id}_selectOption`}
        >
          Select option
        </div>
      ) : (
        <>
          {selection.map((selected) => (
            <div
              key={selected[valueKey]}
              id={`${id}_${selected[valueKey]}_selection`}
              className={`flex justify-center items-center m-1 font-medium py-1 
              px-2 bg-white rounded-full border ${textPriamry700} ${bgPrimary100} ${borderPrimary300}`}
            >
              <div className="text-xs font-normal leading-none max-w-full flex-initial text-gray-700">
                {selected[textKey]}
              </div>
              {!lockedOptionsMap[selected.id] && (
                <div className="flex flex-auto flex-row-reverse ml-1 -mt-4">
                  <XCircleIconSolid
                    name="close"
                    className={`h-4 w-4 fill-current ${textPrimary600} ${hoverTextPriamry700} -mb-5 ml-1 cursor-pointer`}
                    onClick={() => remove(selected)}
                    id={`${id}_${selected[valueKey]}_selection_remove`}
                  />
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

JitsuMultiselectSelection.defaultProps = {
  selection: [],
  textKey: '',
  id: '',
  valueKey: '',
  lockedOptionsMap: {},
  remove: () => {},
  clickSelectionArea: () => {},
}

JitsuMultiselectSelection.propTypes = {
  selection: PropTypes.array.isRequired,
  textKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  clickSelectionArea: PropTypes.func.isRequired,
  lockedOptionsMap: PropTypes.object.isRequired,
}

export default JitsuMultiselectSelection
